@charset "utf-8";

/* type scale */
$type-size-1                : 2.441em !default;  // ~39.056px
$type-size-2                : 1.953em !default;  // ~31.248px
$type-size-3                : 1.563em !default;  // ~25.008px
$type-size-4                : 1.25em !default;   // ~20px
$type-size-5                : 1em !default;      // ~16px
$type-size-6                : 0.75em !default;   // ~12px
$type-size-7                : 0.6875em !default; // ~11px
$type-size-8                : 0.625em !default;  // ~10px

$primary-color: #67499c !default;
$link-color: #67499c !default;

// $primary-color: #6f42c1 !default;
// $link-color: #6f42c1 !default;


@import "minimal-mistakes/skins/contrast"; // skin
@import "minimal-mistakes"; // main partials

// Content wider in maximum size
@media (min-width: 80em) {
  #main {
    max-width: 1480px;
  }
}

a {
  text-decoration: none;
}

h3 {
  font-size: 1.12em;
}

h4 {
  font-size: 1em;
}
